<template>
  <!-- 上传图片 -->
  <div>
    <van-uploader
        v-model="fileList"
        :before-read="beforeRead"
        :after-read="afterRead"
        :before-delete="beforeDelete"
        :max-count="maxCount"
    />
  </div>
</template>

<script>
import * as imageConversion from 'image-conversion'
import {uploadImage} from '@/api'
export default {
  name: 'Upload',
  props: {
    maxCount: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      fileList: [],
      images: []
    }
  },
  methods: {
    beforeRead(file) {
      this.$toast({
        message: '图片压缩中，请耐心等待...',
        forbidClick: true
      })
       // 上传之前校验
      return new Promise((resolve, reject) => {
        console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        imageConversion.compressAccurately(file, 1024 * 10).then(res => { // console.log(res)
          res = new File([res], file.name, { type: res.type, lastModified: Date.now() })
          console.log('压缩后', res)
          resolve(res)
        }).catch(err => {
          reject(err)
        })
      })
    },
    afterRead(file) {
      console.log(file)
      file.status = 'uploading'
      file.message = '上传中...'
      let formData = new FormData()
      formData.append('files', file.file)
      uploadImage(formData).then((res) => {
        console.log('图片上传', res)
        if (res.code !==  0) {
          file.status = 'failed'
          file.message = '上传失败'
        } else {
          file.status = 'done'
          this.images.push(res.data[0])
          this.$emit('up-loader', this.images)
        }
        // this.images.push(res.data[0])
      }).catch(() => {
        file.status = 'failed'
        file.message = '上传失败'
      })
    },
    beforeDelete(file, detail) {
      this.fileList.splice(detail.index, 1)
      this.images.splice(detail.index, 1)
      this.$emit('up-loader', this.images)
    }
  }
}
</script>

<style lang="scss" scoped></style>