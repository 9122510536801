<template>
  <!-- 维修端-维修详情 -->
  <van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
    <div class="index">
      <div class="steps">
        <div>
          维修进度：
          <span>{{detailObj.status_text}}</span>
        </div>
        <div>
          维修加急状态：
          <span :style="{color: detailObj.is_urgenth ? 'red' : '#666'}">{{detailObj.is_urgenth ? '特急' : '普通'}}</span>
        </div>
      </div>

      <div class="index-content">
        <div class="index-hd">
          <h4>{{detailObj.order_no}}</h4>
          <span class="font-24">{{detailObj.create_time_text}}</span>
        </div>
        <!-- 现场查看 -->
        <section v-if="active === 0">
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">商户信息：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{ sh_data.sh_name}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">联 系 人 ：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">
                {{detailObj.contact}}
                <a
                  :href="'tel:' + detailObj.mobile"
                  style="text-decoration: underline; color: #1989fa; padding-left: 5px;"
                >{{detailObj.mobile}}</a>
              </span>
            </van-col>
          </van-row>
          <van-row v-if="detailObj.status !== 1" class="row">
            <van-col span="6">
              <span class="font-30 label">维 修 员 ：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">
                {{woker_data.staff_name}}
                <a
                  style="color: #999; padding-left: 5px;"
                >{{woker_data.mobile}}</a>
              </span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">是否返修：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{detailObj.is_old ? '旧问题返修' : '新问题'}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">维修区域：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{repairAreaName}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">维修类型：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{detailObj.type}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">问题描述：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 desc">{{detailObj.description || '无'}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">现场照片：</span>
            </van-col>
            <van-col span="18">
              <div v-if="detailObj.images" class="pics">
                <van-image
                  v-for="(pic, i) in detailObj.images.split(',')"
                  :key="i"
                  class="pic"
                  width="4rem"
                  height="3rem"
                  fit="cover"
                  :src="pic"
                  @click="imagePreview(detailObj.images.split(','), i)"
                />
              </div>
              <span v-else class="font-30 desc">{{detailObj.type}}</span>
            </van-col>
          </van-row>
          <div class="index-btns">
            <van-button
              v-if="detailObj.status === 1 && detailObj.is_distribution"
              type="danger"
              size="small"
              round
              block
              @click="handleOption('派单')"
            >派单</van-button>
            <van-button
              v-if="detailObj.status === 1 && detailObj.is_show_button && !detailObj.is_distribution"
              type="primary"
              size="small"
              round
              block
              @click="handleGrabOrder"
            >立即抢单</van-button>
            <van-button
              v-if="[2, 3].includes(detailObj.status) && detailObj.is_show_button"
              type="success"
              :loading="disabled"
              loading-text="等待商户确认"
              :disabled="disabled"
              size="small"
              round
              block
              @click="onArrive"
            >{{disabled ? '等待确认' : '确认到场'}}</van-button>
            <van-button
              v-if="detailObj.is_show_button && detailObj.status !== 1 && !detailObj.is_distribution"
              type="warning"
              size="small"
              round
              block
              style="width: 80px;"
              @click="handleOption('转单')"
            >转单</van-button>
            <van-button
              v-if="detailObj.is_show_button && detailObj.status !== 1"
              type="danger"
              size="small"
              round
              block
              @click="isShowCancel = true"
            >工程退单</van-button>
          </div>
        </section>

        <!-- 现场维修 -->
        <section v-else-if="active === 1">
          <van-row v-if="[3, 4].includes(detailObj.status)" class="row">
            <van-col span="6">
              <span class="font-30 label">到达时间：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{status_logs[3].create_time_text}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">维修方案：</span>
            </van-col>
            <van-col span="18">
              <van-field
                v-if="!detailObj.plan && detailObj.is_show_button"
                class="textarea"
                type="textarea"
                maxlength="100"
                show-word-limit
                v-model="plan"
              />
              <span v-else-if="detailObj.plan" class="font-30 desc">{{detailObj.plan}}</span>
            </van-col>
          </van-row>
          <van-row class="row" align="center">
            <van-col span="6">
              <span class="font-30 label">维修费用：</span>
            </van-col>
            <van-col span="18">
              <van-field
                v-if="!Number(detailObj.amount) && detailObj.is_show_button"
                class="money"
                type="number"
                icon-prefix="chame"
                left-icon="money"
                v-model="amount"
              />
              <span v-else-if="Number(detailObj.amount)" class="font-30 value">￥{{detailObj.amount}}（{{detailObj.status_text}}）</span>
            </van-col>
          </van-row>

          <van-row v-if="[5, 6, 9].includes(detailObj.status)" class="row" align="center">
            <van-col span="6">
              <span class="font-30 label">确认时间：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{status_logs[5].create_time_text}}</span>
            </van-col>
          </van-row>

          <div class="index-btns">
            <van-button
              v-if="[4].includes(detailObj.status) && detailObj.is_show_button"
              type="danger"
              size="small"
              round
              block
              @click="isShowCancel = true"
            >工程退单</van-button>
            <van-button
              v-if="[4, 41].includes(detailObj.status) && detailObj.is_show_button"
              type="primary"
              size="small"
              :disabled="detailObj.status === 41"
              :loading="detailObj.status === 41"
              loading-text="等待商户确认"
              round
              block
              @click="submitPlan"
            >提交维修方案</van-button>
            <van-button
              v-if="[4].includes(detailObj.status) && detailObj.is_show_button"
              type="warning"
              size="small"
              round
              block
              style="width: 80px;"
              @click="handleOption('转单')"
            >转单</van-button>
            <van-button
              v-if="[5].includes(detailObj.status) && detailObj.is_show_button"
              type="primary"
              block
              round
              size="small"
              @click="active = 3"
            >开始维修</van-button>
          </div>
        </section>

        <!-- 完成维修 -->
        <section v-else>
          <div v-if="[5].includes(detailObj.status) && detailObj.is_show_button">
            <div class="font-30" style="margin:20px 0 10px;">上传照片（最多5张）：</div>
            <!-- 上传图片 -->
            <my-upload @up-loader="uploader" />
          </div>
           <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">{{ detailObj.inside_uid ? '报修人' : '商户信息' }}：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{ sh_data.sh_name}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">联 系 人 ：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">
                {{detailObj.contact}}
                <a
                  :href="'tel:' + detailObj.mobile"
                  style="text-decoration: underline; color: #1989fa; padding-left: 5px;"
                >{{detailObj.mobile}}</a>
              </span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">维 修 员 ：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">
                {{woker_data.staff_name}}
                <a
                  :href="'tel:' + woker_data.mobile"
                  style="text-decoration: underline; color: #1989fa; padding-left: 5px;"
                >{{woker_data.mobile}}</a>
              </span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">维修类型：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{detailObj.type}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="6">
              <span class="font-30 label">问题描述：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 desc">{{detailObj.description || '无'}}</span>
            </van-col>
          </van-row>
          <van-row v-if="detailObj.amount" class="row" align="center">
            <van-col span="6">
              <span class="font-30 label">维修费用：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">￥{{detailObj.amount}}</span>
            </van-col>
          </van-row>
          <!-- 现场照片 -->
          <van-row v-if="detailObj.images" class="row">
            <van-col span="6">
              <span class="font-30 label">维修前：</span>
            </van-col>
            <van-col span="18">
              <div v-if="detailObj.images" class="pics">
                <van-image
                  v-for="(pic, i) in detailObj.images.split(',')"
                  :key="i"
                  class="pic"
                  width="4rem"
                  height="3rem"
                  fit="cover"
                  :src="pic"
                  @click="imagePreview(detailObj.images.split(','), i)"
                />
              </div>
              <span v-else class="font-30 desc">{{detailObj.type}}</span>
            </van-col>
          </van-row>
          <van-row v-if="[6, 9].includes(detailObj.status)" class="row">
            <van-col span="6">
              <span class="font-30 label">维修结果：</span>
            </van-col>
            <van-col span="18">
              <div v-if="detailObj.result_images" class="pics">
                <van-image
                  v-for="(pic, i) in detailObj.result_images.split(',')"
                  :key="i"
                  class="pic"
                  width="4rem"
                  height="3rem"
                  fit="cover"
                  :src="pic"
                  @click="imagePreview(detailObj.result_images.split(','), i)"
                />
              </div>
            </van-col>
          </van-row>
          <van-row v-if="[7, 8, 9].includes(detailObj.status)" class="row">
            <van-col span="6">
              <span class="font-30 label">维修进度：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{detailObj.status_text}}</span>
            </van-col>
          </van-row>
          <van-row v-if="[7, 8, 10].includes(detailObj.status)" class="row">
            <van-col span="6">
              <span class="font-30 label">{{detailObj.status === 8 ? '取消' : '退单'}}原因：</span>
            </van-col>
            <van-col span="18">
              <span class="font-30 value">{{detailObj.remark}}</span>
            </van-col>
          </van-row>
          <van-row v-if="[7, 8, 10].includes(detailObj.status)" class="row">
            <van-col span="6">
              <span class="font-30 label">{{detailObj.status === 8 ? '取消' : '退单'}}时间：</span>
            </van-col>
            <van-col span="18">
              <span
                class="font-30 value"
              >{{status_logs[7].create_time_text || status_logs[8].create_time_text}}</span>
            </van-col>
          </van-row>

          <div class="index-btns">
            <van-button
              v-if="[7].includes(detailObj.status) && detailObj.is_leader && detailObj.inside_uid"
              type="warning"
              size="small"
              round
              block
              style="width: 80px;"
              @click="handleOption('转单')"
            >转单</van-button>
            <van-button
              v-if="[6, 7].includes(detailObj.status) && detailObj.is_leader && detailObj.inside_uid"
              type="danger"
              size="small"
              round
              block
              @click="onSureFinished"
            >确认</van-button>

            <van-button
              v-else-if="[5, 6].includes(detailObj.status) && detailObj.is_show_button"
              type="success"
              size="small"
              round
              block
              :loading="detailObj.status === 6"
              loading-text="等待确认"
              @click="onFinished"
            >完成维修</van-button>

            <van-button
              v-if="[9].includes(detailObj.status) && detailObj.is_leader"
              type="danger"
              size="small"
              plain
              round
              block
              to="/repair/list"
            >关闭页面</van-button>
            <van-button
              v-else-if="[7, 8, 9].includes(detailObj.status) && !detailObj.is_leader"
              type="success"
              size="small"
              plain
              round
              block
              to="/repair/helper"
            >返回首页</van-button>
          </div>
        </section>
      </div>
      <!-- 提交方案确认弹窗 -->
      <van-dialog
        v-model:show="isShowPlan"
        title="确认提交方案"
        show-cancel-button
        @confirm="onConfirmSubmit"
      >
        <div class="dialog-content">
          <van-row class="row">
            <van-col span="8">
              <span class="font-30 label">维修方案：</span>
            </van-col>
            <van-col span="16">
              <span class="font-30 desc">{{plan}}</span>
            </van-col>
          </van-row>
          <van-row class="row">
            <van-col span="8">
              <span class="font-30 label">维修费用：</span>
            </van-col>
            <van-col span="16">
              <span class="font-30 value">{{amount}}元</span>
            </van-col>
          </van-row>
        </div>
      </van-dialog>

      <van-dialog
        v-model:show="isShowCancel"
        title="退单原因"
        show-cancel-button
        :before-close="cancelOrder"
      >
        <div class="dialog-radio">
          <van-checkbox v-model="checkedCancel" @change="onChangeCancel">
            <span
              class="font-28"
              :style="{color: checkedCancel ? '#333': '#999'}"
            >该故障为租户自建设施设备，无法提供维修服务，予以退单</span>
          </van-checkbox>
        </div>
        <div class="dialog-textarea">
          <van-field type="textarea" :disabled="checkedCancel" maxlength="100" show-word-limit autosize v-model="remark" />
        </div>
      </van-dialog>

      <van-popup v-model:show="isShowTurn" round>
        <div class="popup-content">
          <h3>可{{typeWorker}}人员：</h3>
          <van-radio-group v-model="turn_uid">
            <van-collapse v-model="activeName" accordion>
              <template v-for="(item, index) in workerList" :key="index">
                <van-collapse-item :title="item.depart_name" :name="item.depart_id">
                  <div class="radio-list">
                    <van-radio
                      class="radio-item"
                      v-for="(child, i) in item.data"
                      :key="i"
                      :name="child.staff_id"
                      icon-size="12"
                    >{{child.staff_name}}</van-radio>
                  </div>
                </van-collapse-item>
              </template>
            </van-collapse>
          </van-radio-group>
          <div class="popup-btn">
            <van-button type="primary" size="small" round block @click="onConfirmTurn">确认</van-button>
          </div>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>
import wx from 'weixin-js-sdk'
import {
  getRepairDetailApi,
  postArriveApi,
  postRepairPlanApi,
  postRaceOrderApi,
  postSubmitResultApi,
  postCancelOrderApi,
  getWorkerListApi,
  postTurnOrderApi,
  postSureResultApi,
  postSendOrderApi
} from '@/api'
import { ImagePreview } from 'vant'
import MyUpload from '@/components/upload/index.vue'
export default {
  name: 'RepairDetail',
  components: {
    MyUpload
  },
  data() {
    return {
      checkedCancel: false, // 选择退单原因
      isShowPlan: false, // 显示提交方案确认弹窗
      isShowCancel: false, // 显示退单弹窗
      isRefresh: false, // 刷新状态
      isShowTurn: false,
      user_info: {}, // 用户信息
      order_id: 0, // 维修单ID
      active: 0, // 维修进度
      detailObj: {}, // 维修详情数据
      repairAreas: [
        {
          "id" : 1,
          "name" : "商业户内"
        },
        {
          "id" : 2,
          "name" : "商业公区"
        },
        {
          "id" : 3,
          "name" : "办公户内"
        },
        {
          "id" : 4,
          "name" : "办公公区"
        },
      ], // 维修区域
      disabled: false,
      plan: '', // 维修方案
      amount: null, // 维修费用
      status_logs: {}, // 状态发生改变时间
      sh_data: {}, // 商户信息
      result_images: [], // 上传维修结果照片
      remark: '', // 工程退单原因
      activeName: '',
      woker_data: {}, // 维修员信息
      workerList: [], // 当天出勤人员列表
      turn_uid: 0, // 需要转到的人员uid
      typeWorker: '转单'
    }
  },
  created() {
    if (!window.localStorage.getItem('user_info')) return
    this.user_info = JSON.parse(window.localStorage.getItem('user_info'))
    if (!this.$route.query.order_id) return
    this.order_id = this.$route.query.order_id
    // 获取维修单详情
    this.getRepairDetailData()
  },
  computed: {
    repairAreaName() {
      return this.repairAreas.find(item => item.id === this.detailObj.area)?.name || '';
    }
  },
  methods: {
    getRepairDetailData() {
      getRepairDetailApi({
        access_token: this.user_info.token,
        order_id: this.order_id,
      }).then((res) => {
        console.log('维修单详情', res)
        if (res.code !== 0) return
        this.detailObj = res.data
        this.status_logs = res.data.status_logs
        this.sh_data = res.data.sh_data
        if (res.data.status !== 1) {
          this.woker_data = res.data.woker_data
        }
        if ([1, 2, 3].includes(res.data.status)) {
          this.active = 0
        } else if ([4, 41].includes(res.data.status)) {
          this.active = 1
        } else {
          this.active = 2
        }
        // 等待商户确认到场事件
        this.disabled = res.data.status === 3
        // 获取当天维修员排班
        this.getWorkerListData()
      })
    },
    getWorkerListData() {
      getWorkerListApi({
        access_token: this.user_info.token,
      }).then((res) => {
        console.log('当天出勤人员', res)
        if (res.code !== 0) return
        this.workerList = res.data
      })
    },
    // 选择工程退单原因
    onChangeCancel(flag) {
      if (flag) {
        this.remark = '该故障为租户自建设施设备，无法提供维修服务，予以退单'
      } else {
        this.remark = ''
      }
    },
    // 下拉刷新
    onRefresh() {
      this.getRepairDetailData()
      this.isRefresh = false
    },
    // 图片预览
    imagePreview(images, i) {
      ImagePreview({
        images,
        startPosition: i,
      })
    },
    // 图片上传
    uploader(images) {
      this.result_images = images
    },
    // 删除图片
    deleteImage(i) {
      this.result_images.splice(i, 1)
    },
    // 立即抢单
    handleGrabOrder() {
      postRaceOrderApi({
        access_token: this.user_info.token,
        order_id: this.order_id,
      }).then((res) => {
        console.log('抢单', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '抢单成功',
          onClose: () => {
            this.getRepairDetailData()
          },
        })
      })
    },
    // 确认到场
    onArrive() {
      console.log('确认到场')
      this.$dialog({
        message: '您确定已到达维修现场？',
        showCancelButton: true,
      })
        .then(() => {
          console.log('确定')
          postArriveApi({
            access_token: this.user_info.token,
            order_id: this.order_id,
          }).then((res) => {
            console.log('到达现场', res)
            if (res.code !== 0) return
            this.getRepairDetailData()
          })
        })
        .catch(() => {
          console.log('取消')
        })
    },
    // 提交维修方案
    submitPlan() {
      console.log('提交方案')
      if (!this.plan) return this.$toast('维修方案不能为空！')
      if (!this.amount) return this.$toast('维修费用不能为空！')
      this.isShowPlan = true
    },
    // 确认提交维修方案
    onConfirmSubmit() {
      postRepairPlanApi({
        access_token: this.user_info.token,
        order_id: this.order_id,
        amount: this.amount,
        plan: this.plan,
      }).then((res) => {
        console.log('维修方案提交', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '提交成功！',
          onClose: () => {
            this.getRepairDetailData()
          },
        })
      })
    },
    // 完成维修
    onFinished() {
      console.log('完成维修')
      if (this.result_images.length === 0) return this.$toast('请上传维修照片！')
      postSubmitResultApi({
        access_token: this.user_info.token,
        order_id: this.order_id,
        result_images: this.result_images.join(','),
      }).then((res) => {
        console.log('完成维修', res)
        if (res.code !== 0) return
        this.$toast({
          type: 'success',
          message: '提交成功！',
          onClose: () => {
            this.getRepairDetailData()
          },
        })
      })
    },
    // 工程退单
    cancelOrder(action) {
      console.log(action)
      if (action === 'confirm') {
        if (!this.remark) {
          this.$toast('请输入退单原因！')
          return false
        }
        postCancelOrderApi({
          access_token: this.user_info.token,
          order_id: this.order_id,
          remark: this.remark,
        }).then((res) => {
          console.log('工程退单', res)
          if (res.code !== 0) return
          this.$toast({
            type: 'success',
            message: '退单成功！',
            onClose: () => {
              this.getRepairDetailData()
            },
          })
        })
        return true
      } else {
        return true
      }
    },
    handleOption(type) {
      this.typeWorker = type
      this.isShowTurn = true
    },
    // 确认转单
    onConfirmTurn() {
      if (!this.turn_uid) return this.$toast('请选择' + this.typeWorker + '人员！')
      if (this.typeWorker === '派单') {
        postSendOrderApi({
          access_token: this.user_info.token,
          distribution_uid: this.turn_uid,
          order_id: this.order_id,
        })
          .then((res) => {
            console.log('派单', res)
            if (res.code !== 0) return
            this.$toast({
              type: 'success',
              message: '派单成功！',
              onClose: () => {
                this.onChange()
              },
            })
            this.isShow = false
          })
          .catch(() => {
            this.isShow = false
            this.$toast.fail('派单失败！')
          })
      } else {
        postTurnOrderApi({
          access_token: this.user_info.token,
          turn_uid: this.turn_uid,
          order_id: this.order_id,
        }).then((res) => {
          console.log('转单', res)
          if (res.code !== 0) return
          this.$toast({
            type: 'success',
            message: '转单成功！',
            onClose: () => {
              this.isShowTurn = false
              this.$router.push('/repair/list')
            },
          })
        })
      }
    },

    // 公区报修-确认完成
    onSureFinished() {
      console.log('确认完成')
      postSureResultApi({
        access_token: this.user_info.token,
        order_id: this.order_id
      }).then((res) => {
        if (res.code !== 0) return
        this.getRepairDetailData()
      })
    },
    // 关闭页面
    closePage() {
      wx.closeWindow()
    }
  },
}
</script>

<style lang="scss" scoped>
.index {
  overflow: auto;
  height: 100vh;
  padding: 0 40px;
  background-color: #f7f7f7;

  .index-content {
    margin: 20px 0 40px;
    padding: 0 20px;
    background-color: #fff;
    border-radius: 10px;

    .index-hd {
      padding: 20px 0;
      border-bottom: 1px solid #eee;
      span {
        display: block;
        color: #999;
        white-space: nowrap;
      }
    }

    .index-btns {
      padding: 80px 0 40px;
      display: flex;
      justify-content: center;
      /deep/ .van-button {
        width: 120px;
        margin: 0 5px;
        white-space: nowrap;
      }
    }
  }
  /deep/ .row {
    padding-top: 20px;
    .label {
      color: #666;
    }
    .value {
      font-size: 30px;
      color: #333;
      word-break: break-all;
    }
    .desc {
      display: block;
      color: #999;
      word-break: break-all;
    }
    .pics {
      .pic {
        margin-right: 10px;
      }
    }
    .price {
      color: red;
    }
    .textarea {
      padding: 20px;
      line-height: 1.5;
      background-color: #f7f7f7;
      border-radius: 8px;
    }
    .money {
      padding: 10px 20px;
      background-color: #f7f7f7;
    }
  }
  .dialog-content {
    padding: 40px;
  }

  .dialog-radio {
    margin: 20px 40px;
  }

  .dialog-textarea {
    margin: 20px 40px 40px;
    border: 1px solid #eee;
    border-radius: 8px;
  }

  .popup-content {
    width: 600px;
    padding: 40px 0;
    h3 {
      padding: 0 40px 20px;
    }
    .radio-list {
      display: flex;
      flex-wrap: wrap;
      /deep/ .radio-item {
        width: 30%;
        font-size: 24px;
      }
    }
    /deep/ .van-cell__title {
      font-size: 14px;
      color: #222;
      font-weight: 500;
    }
    .popup-btn {
      padding: 40px 40px 0;
    }
  }
}
</style>
